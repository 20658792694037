export function changeAccent (sbj) {
  let rt = document.querySelector(':root')
  if (sbj == 'ai') {
    rt.style.setProperty('--accent', '#F4941E')
  } else if (sbj == 'tv') {
    rt.style.setProperty('--accent', '#4BAD31')
  } else if (sbj == 'wi') {
    rt.style.setProperty('--accent', '#10B4F2')
  } else {
    rt.style.setProperty('--accent', '#0D3661')
  }
}
